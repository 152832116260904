require('../bootstrap');

/*!
 * SCRIPTS BACKOFFICE i BTOB
 */

// sidebar slide
document.addEventListener("DOMContentLoaded", function(event) {
    
    /**
     * funció per desplegar el sidebar
     */
    const showNavbar = (toggleId, navId, bodyId, headerId, navClass, btnClass) => {
        
        const toggle = document.getElementById(toggleId),
            nav = document.getElementById(navId),
            bodypd = document.getElementById(bodyId),
            headerpd = document.getElementById(headerId),
            navList = document.querySelectorAll(navClass),
            btn = document.querySelectorAll(btnClass);

        // Validate that all variables exist
        if(toggle && nav && bodypd && headerpd && navList){
            toggle.addEventListener('click', () => {
                // show navbar
                nav.classList.toggle('show-nav');
                // change icon
                toggle.classList.toggle('bi-x');
                // add padding to body
                bodypd.classList.toggle('body-pd');
                // add padding to header
                headerpd.classList.toggle('body-pd');
                // unfold collapsed lists
                navList.forEach(element => {
                    element.classList.toggle('show');
                });
                // icon button list
                btn.forEach(element => {
                    element.setAttribute(
                        'aria-expanded', 
                        element.getAttribute('aria-expanded') === 'true' 
                            ? 'false' 
                            : 'true'
                    );
                });
                
            });
        }

    }

    showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header', '.nav_list .collapse', '.nav_list .btn-toggle')

    /* LINK ACTIVE */
    /*
        const linkColor = document.querySelectorAll('.nav_link')

        function colorLink() {
            if (linkColor) {
                linkColor.forEach(l=> l.classList.remove('active-side'))
                this.classList.add('active-side')
            }
        }
        linkColor.forEach(l=> l.addEventListener('click', colorLink))
    */
});

// form logout submit
const iconLogout = document.getElementById('logout-link');
iconLogout.addEventListener('click', () => {
    event.preventDefault();
    document.getElementById('logout-form').submit();                   
});

// scroller marques BEGIN

const scrollers = document.querySelectorAll(".scroller");

// preferències d'usuari ? afegir animació
if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
    addAnimation();
}

function addAnimation() {
    if (scrollers) {        
        scrollers.forEach((scroller) => {
    
            scroller.setAttribute("data-animated", true);
    
            const scrollerInner = scroller.querySelector(".scroller__inner");
            // crear array
            const scrollerContent = Array.from(scrollerInner.children);
    
            // clonar elements
            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                duplicatedItem.setAttribute("aria-hidden", true);
                scrollerInner.appendChild(duplicatedItem);
            });
        });
    }
}

// scroller marques END